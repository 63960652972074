@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Assistant&display=swap");

.allTableStyle {
  direction: rtl;
  width: 100%;
  justify-content: center;
  color: $tableTextColor;
  border-collapse: collapse;
  font-size: 2vh;

  thead {
    display: table-header-team;
    border-color: inherit;

    tr {
      cursor: unset;
      height: 5vh;

      th {
        background-color: $thBackgroundColor;
        padding-top: 2vh;
        padding-bottom: 2vh;
        text-align: right;

        &.align-center {
          text-align: center;

          &:last-child {
            padding-left: 1.5vw;
          }
        }
      }

      .kidName,
      .forwards,
      .backwards {
        width: 17%;
      }

      .picture {
        min-width: calc(40px + 6vw);
      }

      .sync {
        min-width: 2.3vh;
      }

      .info {
        min-width: calc(3vw + 3vh);
      }
    }
  }

  .tableBodyStyle {
    td {
      @include ellipse-overflow;
      padding-top: 2vh;
      padding-bottom: 2vh;
      background-color: white;
      text-align: right;

      &:last-child.icon {
        padding-left: 3vw;
        text-align: left;
      }

      .clipboard-list {
        font-size: 11px;
      }
    }

    .list {
      padding: 0;
      text-align: center;
    }

    .numOfKids {
      padding: 0;
      text-align: center;
    }

    .first-column {
      font-weight: 600;
    }

    .kidName {
      width: 17%;
    }

    .empty {
      width: 15vw;
      min-width: 15vw;
    }

    .trash-edit-icons {
      cursor: pointer;
    }

    .sync,
    .info {
      font-size: 2.3vh;
      padding: 0;
    }

    .info {
      padding-top: 0.7vh;
    }

    .align-center {
      text-align: center;
    }
  }

  .location {
    font-size: 2vh;
  }

  tr {
    height: 10vh;
    border-bottom: solid 1px $tableBorder;

    .noResults {
      padding: 3vh;
      vertical-align: middle;
      text-align: center;
      background-color: white;
    }

    .src img {
      width: 3.7vh;
    }
  }
}

.tableShowMore {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 7vh;

  .show-more {
    color: $tableTextColor;
    font-size: 2.1vh;
    cursor: pointer;
    padding: 0 0.2vw 0.2vh 0.2vw;
    border-bottom: solid 1px $tableTextColor;
  }
}

.tableNavigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $tableTextColor;
  font-size: 2.3vh;
  height: 7vh;

  .navArrow {
    margin: 0 2vh;
    font-size: 2vh;
    cursor: pointer;
  }

  .disabledNavArrow {
    color: $thBackgroundColor;
    cursor: unset;
  }
}

.purchases {
  td {
    width: 15vw;
  }

  .license-name, .username {
    max-width: 15vw;
    width: 15vw;
    div {
      @include ellipse-overflow;
    }
  }


  td.status, th.status {
    width: 3vw;
    text-align: center;
  }

  .more-info {
    .icon {
      min-height: 50%;
      min-width: 2vw;
    }
  }

  .failure {
    color: $failure-red;
  }

  .success {
    color: $success-green;
  }

  .waiting {
    color: $waiting-yellow;
  }

  .refund {
    color: $refund-purple;
  }
}

.trash,
.pen {
  text-align: left;
  color: $darkBlue;
  font-size: 13px;
  padding: 0.8vh 1.5vw;
  box-sizing: content-box;
}

.table-and-navigation {
  box-shadow: 3px 4px 6px #0000000a;
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 5vh;
}

.projects,
.purchases,
.organizations {
  @include tablesPaddingRight;
  tr {
    cursor: pointer;
  }
}

.organizations {
  td:first-child,
  tr:first-child {
    min-width: 20%;
  }

  td:nth-child(2),
  th:nth-child(2) {
    min-width: 15%;
  }

  td:nth-child(3),
  th:nth-child(3) {
    min-width: 20%;
  }

  td:nth-child(4),
  th:nth-child(4) {
    min-width: 20%;
    padding-left: 3vw;
  }

  td {
    text-align: center;
  }
}
.icon {
  margin: 0 2vh;
  font-size: 2.5vh;
  cursor: pointer;
  min-width: 0.5vw;
  max-width: 0.5vw;
  transition: 0.3s;

  &:hover {
    color: #5c5d63;
  }
}

.projects {
  .project-name {
    max-width: 10vw;
    min-width: 03vw;

    div {
      @include ellipse-overflow;
    }
  }

  .login-type {
    min-width: 7vw;
    max-width: 7vw;
  }

  .domain {
    max-width: 18vw;
    min-width: 18vw;
    padding-left: 1rem;

    div {
      @include ellipse-overflow;
    }
  }

  .number-of-organization {
    max-width: 7vw;
    min-width: 7vw;

    div {
      @include ellipse-overflow;
    }
  }
}
