@import "./variables.scss";

.inputs-block {
    width: 95%;
    height: fit-content;
    box-shadow: 0px 2px 6px $shadowColor;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 3vh 1vw 1vh;
    margin-top: 1.5rem;
    position: relative;

    &--label {
        width: 80%;
    }

    &--button {
        display: flex;
        height: 1.5rem;
        justify-content: space-between;
        align-content: center;
        padding: 0 2vw;

        .form--button {
            margin: 0;
        }

        .title {
            font-size: 2.3vh;
        }
    }

    .break {
        border: 0.5px solid $thBackgroundColor;
        box-shadow: 0px 2px 6px #0000000a;
        width: 100%;
        opacity: 0.6;
    }

    &--loading {
        background-color: rgba(161, 161, 161, 0.301);
        width: 100%;
        height: 100%;
        z-index: 100;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
