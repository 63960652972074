@import "./variables.scss";

.page {
    .top-page {
        display: flex;
        margin-top: 3vh;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
        .header{
            display: flex;
            color: $grayHeader;
            align-items: center;
            font-size:3.5vh;
            max-width: 93%;

            &--content {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
