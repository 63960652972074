@import './variables.scss';

.tabs {
    margin-top: 3vh;
    font-size: 2vh;

    ul {
        list-style: none;
        display: flex;
        padding-right: 0;
        margin-bottom: 0;

        a {
            margin: 0 1vw 0 2vw;
            text-decoration: none;
            outline: none;
            user-select: none;

            &:first-child {
                margin-right: 0;
            }
            &:last-child {
                margin-left: 0;
            }
        }

        li {
            width: 100%;
            color: $darkBlue;
        }

        .boldTopBar{
            font-weight: bold;
        }
    }
}
