@import "./variables.scss";

.login-component {
    height: 100vh;
    justify-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form {
        margin-top: 10vh;
        width: 25%;
        justify-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        input {
            height: 3rem;
            border-radius: 12px;
            border: 1px solid rgb(77, 76, 76);

            padding: 1rem;
            width: 100%;
            outline: none;
            font-family: "Heebo";
            font-size: medium;

            &.error {
                border: 1px #e1663c solid;
            }
        }

        .email,
        .code {
            margin-top: 7vh;
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .password {
            width: 100%;
            position: relative;
            margin-top: 0.3rem;

            .password-icon {
                left: 0.8rem;
                top: 1.01rem;
                position: absolute;
                height: 1rem;
            }
        }

        .submit-input {
            width: 100%;
            height: 3rem;
            border: none;
            outline: none;
            color: white;
            font-size: medium;
            font-family: "Heebo";
            margin-top: 0rem;
            margin-bottom: 0.5rem;
            border-radius: 12px;
            background-color: $darkBlue;
            cursor: pointer;
            transition: 0.5s;

            &:disabled {
                opacity: 0.5;
            }
        }

        .under-submit {
            margin-top: 0.2rem;
            margin-bottom: 0;
        }
    }

    .error-message {
        width: 100%;
        font-size: 0.8rem;
        color: #e1663c;
        height: 1rem;
        text-align: right;
        // padding-right: 5%;
        display: flex;
        align-items: center;
        .info-icon {
            margin-left: calc(1rem - 1em);
        }
    }

    .link {
        color: #323b74;
        cursor: pointer;
        text-decoration: underline;
    }

    .title {
        color: #323b74;
        font-weight: bold;
        font-size: 3rem;
        white-space: nowrap;
    }
}

.login-image {
    width: 11vw;
    height: 5.5vh;
    margin-right: 86vw;
    margin-top: -5.5vh;
    display: flex;
}
