@use "sass:math";

$backgroundColor: #f8f8f8;
$backgroundColorSearch: #f1f0f0;
$scrollbar-color: #bebfc0b7;
$darkBlue: #2a3474;
$grayHeader: #64646d;
$tableTextColor: #737479;
$thBackgroundColor: #e4e5e9;
$tableBorder: #e3e4e773;
$topbarHeight: math.div(1, 12) * 100vh;
$mustard: #fcb24d;
$plusButtomWhite: #f5f6fa;
$shadowColor: #0000000a;
$white: #FFFFFF;
$formInput: #c3c3d4;
$buttonOrenge: #E1663C;
$failure-red: #e83535;
$success-green: #6CC068;
$waiting-yellow: #FCB24D66;
$refund-purple: #2a3474;


@font-face {
    font-family: "Heebo";
    src: url("../media/Heebo-VariableFont_wght.ttf");
}

@mixin tablesPaddingRight {
    th {
        padding-right: 3vw;
        white-space: nowrap;
        &.icon{
            padding-right: 0;
            padding-left: 1.5rem;

        }
    }

    td {
        padding-right: 3vw;
        &.icon{
            padding-right: 0;

        }
    }
}

@mixin disabled {
    opacity: 0.4;
    cursor: unset;
    pointer-events: none;
}

@mixin flex-center-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin ellipse-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
