@import './variables.scss';

* {
    box-sizing: border-box;
    user-select: none;
    font-family: "Heebo";

    input,
    table div {
        user-select: text;
    }
}

.App {
    background-color: #F6F6F6;
    font-family: 'Heebo';
    text-align: center;
    
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

body {
direction: rtl;
overflow: hidden;
}

.main-app {
    background-color: $backgroundColor;
    overflow: hidden;

    .main-content {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 90vh;
        overflow-y: scroll;
        overflow-x: hidden;
        direction: ltr;
        bottom: 0;

        &-inside {
            width: 72%;
            justify-self: center;
            direction: rtl;

            display: flex;
            flex-direction: column;
            
        }
    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: $scrollbar-color;
}
