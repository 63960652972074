@use "sass:math";
@import "./variables.scss";

.topbar{
    box-shadow: 0px 2px 6px  rgba(0,0,0,0.54);
    background-color: $darkBlue;
    height: $topbarHeight;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 1.5rem;
    width: 100vw;

    &--right{
        width: 50%;
        height: 50%;
        display: flex;
        align-items: center;

        .user-img {
            border-radius: 50%;
            height: 100%;
            width: math.div(5, 10) * $topbarHeight;
            object-fit: cover;
            font-size: 1rem;
            margin-left: .5rem;
            cursor: pointer;
        }

        .user-name{
            font-family: "Heebo";
            font-size: math.div(5, 20) * $topbarHeight;
            color: white;
        }

    }

    &--left{
        width: 50%;
        height: 60%;

        .logo {
            float: left;
            height: 100%;
            width: auto;
        }
    }
}