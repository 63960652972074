@use "sass:math";
@import './variables.scss';

.full-loading {
    position: absolute;
    top: 0;
    height: calc(100% + 2rem);
    width: calc(100% + 2rem);
    z-index: 100;
    // background-color: $scrollbar-color;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}