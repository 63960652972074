@import "./variables.scss";

.license-drop-container {
    width: fit-content;
    z-index: 100;
    position: absolute;
    align-self: center;
    top: $topbarHeight;

    .license-drop-list {
        background: #f8f8f8;
        z-index: 100;
        margin-right: auto;
        width: fit-content;
        padding: 0 1rem;
        box-shadow: 0px 2px 10px #00000029;
        border-radius: 0 0 7px 7px;
        overflow-y: hidden;
        transition: 0.2s;
        overflow-x: hidden;
        box-sizing: border-box;

        &--item {
            font-size: .8rem;
            line-height: 2rem;
        }
    }
}
