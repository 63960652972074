@import "./variables.scss";

.addEditOrganization {
    .top-page {
        .form--button {
            height: 3.5vh;
            max-height: 3.5vh;
            user-select: none;
            width: 6vw;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.2vh;
        }
    }
    .details {
        background-color: $white;
        box-shadow: 5px 6px 6px $shadowColor;
        border-radius: 10px;
        margin-top: 2vh;

        .bar-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 8vh;
            box-shadow: 0px 2px 6px $shadowColor;

            .bar {
                list-style: none;
                padding-right: 0;
                display: flex;
                margin-right: 8vw;

                li {
                    cursor: pointer;
                    user-select: none;
                    margin-left: 4vw;
                    color: $grayHeader;
                    font-size: 2.1vh;
                }

                .underline {
                    border-bottom: 2px $buttonOrenge solid;
                    font-weight: bold;
                }
            }

            .trash-icon {
                margin-left: 3.5vw;
                font-size: 2.1vh;
                color: $grayHeader;
                cursor: pointer;

                &:hover {
                    color: #88889b;
                }
            }
        }

        .AddEditOrgForm {
            width: 90%;
            .bottomButton {
                display: flex;
                justify-content: flex-end;
                .form--button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.5vh;
                    margin-bottom: 2.5vh;
                    height: 3.3vh;
                    padding: 0 1vw;
                }
            }
        }

        .AddEditOrgLicenses {
            .form--input {
                width: 50%;
            }
        }
    }
}
