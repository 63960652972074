@import './variables.scss';

.register-component {
    height: 100vh;
    justify-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .not-found {
        margin: auto;
        height: 50vh;

        img {
            width: 30%;
        }
    }

    .form {
        margin-top: 10vh;
        width: 25%;
        justify-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        input {
            height: 3rem;
            border-radius: 12px;
            border: 1px solid rgb(77, 76, 76);

            padding: 1rem;
            width: 100%;
            outline: none;
            font-family: 'Heebo';
            font-size: medium;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
        .password {
            width: 100%;
            position: relative;
            margin-top: .3rem;

            .password-icon {
                left: .8rem;
                top: 1.01rem;
                position: absolute;
                height: 1rem;
            }
        }
    
        .submit-input {
            width: 100%;
            height: 3rem;
            border: none;
            outline: none;
            color: white;
            font-size: medium;
            font-family: 'Heebo';
            margin-top: 0rem;
            margin-bottom: .5rem;
            border-radius: 12px;
            background-color: $darkBlue;
            cursor: pointer;
            transition: .5s;

            &:disabled {
                opacity: .5;
            }
        }
        
    }

    .error-message {
        width: 100%;
        font-size: .8rem;
        color: #E1663C;
        height: 1rem;
        text-align: right;
        padding-right: 5%;
    }

    .link {
        color: #323b74;
        cursor: pointer;
        text-decoration: underline;
    }

    .title {
        color: #323b74;
        font-weight: bold;
        font-size: 3rem;
        white-space: nowrap;
        margin-bottom: 7vh;
    }

}


