@import "./variables.scss";

.container {
    height: 100vh;
    width: 100vw;

    .code-verification {
        width: 50%;
    }

    .code-verification,
    .forbidden {
        height: calc(100% - $topbarHeight);
        margin: auto;
        display: flex;
        flex-direction: column;

        .message {
            margin-top: 7vh;

            .details {
                text-align: right;
                font-size: 0.8rem;
                padding-right: 1rem;
            }
        }
    }
}

.not-found {
    margin: auto;
    color: $darkBlue;

    img {
        height: 15rem;
    }

    p {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .link {
        color: #323b74;
        cursor: pointer;
        text-decoration: underline;
    }
}
