@import "./variables.scss";

.search-bar {
    @include flex-center-row;
    width: 100%;
    height: $topbarHeight;
    background-color: white;
    margin: 0.7rem 0;
    box-shadow: 4px 4px 6px #0000000a;
    border-radius: 7px;
    padding: 2vh 2vw;

    &--input-container {
        @include flex-center-row;
        // height: 60%;
        height: 100%;
        flex: 1;
        // width: 90%;
        background-color: $backgroundColorSearch;
        border-radius: 8px;

        .search-bar--icons {
            @include flex-center-row;
            height: 100%;
            width: 5%;

            .input-icon {
                color: $grayHeader;
            }

            .times {
                cursor: pointer;
            }
        }
    }
}
