@use "sass:math";
@import "./variables.scss";

.form {
    &--icon-and-input {
        display: flex;
        background-color: $backgroundColorSearch;
        align-items: center;
        color: $grayHeader;
        border-radius: 4px;

        &.padding {
            padding-left: 1.4vw;
        }

        &.inputError {
            border: 1.5px $buttonOrenge solid;
        }
    }

    &--icon {
        cursor: pointer;
        font-size: 1.8vh;
    }

    &--input {
        height: 100%;
        width: 95%;
        border-radius: inherit;
        outline: none;
        border-style: hidden;
        background-color: $backgroundColorSearch;
        color: $grayHeader;
        padding: 0.5vh 1rem;
        font-size: 1.8vh;

        &.inputError {
            border: 1.5px $buttonOrenge solid;
        }
    }

    &--input::placeholder {
        opacity: 0.5;
    }

    &--input:disabled {
        color: #868691;
        opacity: 0.5;
    }

    &--button {
        width: fit-content;
        height: 100%;
        color: white;
        border-radius: 6px;
        font-family: "Heebo";
        font-size: 80%;
        padding: 0.2rem 0.4rem;
        margin: 0.2rem;
        cursor: pointer;
    }
}

.label-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.5vh 0;

    .label {
        margin-right: 8vw;
        color: $grayHeader;
        font-size: 2.1vh;
    }

    .input-error {
        display: flex;
        flex-direction: column;
        width: 60%;

        .phone-input {
            display: flex;
            align-items: center;

            input {
                text-align: left;
            }

            &--start {
                display: flex;
                align-items: center;
                text-align: center;
                height: 100%;
                border-radius: 4px;
                border-style: hidden;
                border: 2px solid $backgroundColorSearch;
                color: $grayHeader;
                margin-right: 0.5rem;
                padding: 1.2vh 0.3rem;
                font-size: 1.8vh;
                font-weight: bold;
            }
        }

        .error {
            width: 100%;
            font-size: 1.3vh;
            color: $buttonOrenge;
            text-align: right;
        }

        .empty {
            height: 1.3vh;
        }
    }

    .form--input {
        font-size: 1.6vh;
        width: 100%;
        font-size: 2vh;
        padding: 1.2vh 2vw;
    }
}

.label-checkbox {
    display: flex;

    .checkbox {
        margin-right: 8vw;
        height: 2.1vh;
        width: 2.1vh;
    }

    .checkbox-label {
        margin-right: 1vw;
        color: $grayHeader;
        font-size: 2.1vh;
    }
}

.form--button {
    cursor: pointer;
}

.arrow-right {
    margin-left: 1vw;
    cursor: pointer;
}

.circle-button-plus {
    background-color: $mustard;
    padding: 0.5rem;
    color: $plusButtomWhite;
    width: auto;
    height: 50%;
    font-size: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
}
