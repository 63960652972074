@import "./variables.scss";

.popup-container {
    .popup {
        &-with-loading {
            @include disabled;
        }

        &--top {
            width: 100%;
            height: 22%;
            padding: 1rem 1.5rem 0.5rem;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            justify-items: center;

            &--middle {
                @include flex-center-row;
                width: 80%;
                height: 100%;
                align-items: flex-end;

                .title {
                    width: 100%;
                    font-weight: bold;
                    font-family: "Heebo";
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .user-image {
                    border-radius: 50%;
                    height: 2.7rem;
                    width: 2.7rem;
                    object-fit: cover;
                    color: $darkBlue;
                }
            }

            &--left {
                display: flex;
                width: 10%;
                justify-content: flex-end;
            }

            &--right {
                width: 10%;
            }
        }

        &--scroll {
            width: 100%;
            height: 78%;
            direction: ltr;
            display: flex;
            justify-content: center;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: $scrollbar-color;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgb(143, 143, 143);
            }
        }

        &--bottom {
            direction: rtl;
            width: 90%;
            height: 78%;

            display: flex;
            flex-direction: column;
            padding-right: 5px;
            padding-bottom: 1rem;

            .status {
                display: flex;
            }

            .more-info {
                width: 100%;
                height: fit-content;
                max-height: 60vh;
                padding-bottom: 3vh;
                .row {
                    display: flex;
                    gap: 2vw;

                    .title {
                        font-weight: bold;
                    }
                }
                .button {
                    margin-right: auto;
                    width: fit-content;
                }
            }
        }

        &-header {
            &-icon {
                width: 100%;
                direction: rtl;

                .close-icon {
                    cursor: pointer;
                }
            }

            &-title {
                width: 100%;
                font-size: 1.2rem;
                font-weight: bold;
                font-family: "Heebo";
                @include flex-center-column;
            }
        }

        &-body {
            margin: 0.5rem;
            max-width: 100%;
            height: fit-content;
            @include flex-center-column;

            .popup-input {
                width: 100%;
                height: fit-content;
                margin-bottom: 0.1rem;
                @include flex-center-row;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                &-div {
                    align-items: flex-end;
                    width: 100%;
                    height: 2rem;
                    margin-top: 0.3rem;
                }

                &-name {
                    font-size: 0.8rem;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-right: 0.1rem;
                }

            }

            .popup-alert {
                font-size: 0.7rem;
                text-align: center;

                &-bold {
                    font-weight: bold;
                }
            }

            .popup-error {
                max-width: 100%;
                align-self: flex-start;
                font-size: 0.6rem;
                height: 1.2rem;
                color: #e1663c;
                text-align: center;
                text-overflow: ellipsis;
                margin-right: 0.1rem;
            }

            .strong {
                max-width: 100%;
                overflow: hidden;
                // white-space: nowrap;
                word-break: break-word;
                text-overflow: ellipsis;
                display: block;
                text-align: center;
                margin: 0;
                font-weight: bold;
            }
        }

        &-footer {
            @include flex-center-row;
            justify-content: flex-end;

            .form--button {
                border: 1px solid #2eafe5;
            }
        }
    }
}

.popup--loading {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    margin-bottom: 10vh;
    z-index: 30;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.popup {
    &--dialog {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .first-text {
            font-size: 2vh;
            font-weight: bold;
            color: #a3a6b4;
        }

        .second-text {
            color: #a3a6b4;
            font-size: 1.8vh;
            padding: 0.5vh 0;
        }

        .button-div {
            display: flex;
            justify-content: flex-end;
        }

        .bottom-div {
            display: flex;
            flex-direction: column;
            .bottom-warning-text {
                font-size: 1.5vh;
                color: #a3a6b4;
            }

            .button-div {
                display: flex;
                justify-content: flex-end;
                width: 95%;
                .form--button {
                    height: 2.5vh;
                }
            }
        }
    }
}

.license-any-icon {
    .close-icon {
        cursor: pointer;
        color: $tableTextColor;
        transition: 0.3s;

        &:hover {
            color: $grayHeader;
        }
    }
}

.height-and-width {
    width: 95%;
    height: 100%;
}

.disabled {
    @include disabled;
}
